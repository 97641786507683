<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <div class="items" style="flex: none">
        <div class="one-item" style="width: 100px">
          <a-input
              class="input"
              v-model="queryParams.goodId"
              placeholder="商品ID"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.goodName"
              placeholder="商品名称"
              allowClear
          />
        </div>
        <div class="one-item">
          <a-input
              class="input"
              v-model="queryParams.username"
              placeholder="用户名称"
              allowClear
          />
        </div>
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.sendStatus"
              placeholder="登记状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cardOrderSendStatus" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
        <div class="one-item" style="width: 150px">
          <a-select
              class="input"
              v-model="queryParams.kbsStatus"
              placeholder="卡博士状态"
              @change="search(1)"
              allowClear
          >
            <a-select-option v-for="r in dict.cardOrderKbsStatus" :key="r.value.toString()">{{ r.label }}
            </a-select-option>
          </a-select>
        </div>
      </div>
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
      </div>
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >

      <template slot="assignStatus" slot-scope="text, record">
        <a-tag :color="record.assignStatus | dictName('cleanOrderAssignStatus', 'color')" style="cursor: pointer">
          {{ record.assignStatus| dictName("cleanOrderAssignStatus") }}
        </a-tag>
      </template>
      <template slot="payStatus" slot-scope="text, record">
        <a-tag :color="record.payStatus | dictName('orderPayStatus', 'color')" style="cursor: pointer">
          {{ record.payStatus| dictName("orderPayStatus") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button :disabled="record.assignStatus!=-1" type="primary" icon="reload" size="small"
                  @click="assignToken(record)">
          分配
        </a-button>
        <a-button :disabled="record.payStatus!=1" type="danger" icon="delete" size="small" @click="deleteOne(record)"
                  style="margin-left:10px">
          删除
        </a-button>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";

const fullKbsImgPath = function (path) {
  if (!path) {
    return '';
  }
  if (path.indexOf("/uploads") == 0) {
    return "https://wap.kaboshihaoka.com" + path;
  }
  return path
}

export default {
  name: "CleanOrderList",
  mixins: [ListMixin],
  filters: {fullKbsImgPath},
  data() {
    return {
      listGetUrl: "cleanSale/orderList",
      listMethod: "get",
    };
  },
  computed: {
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },
        {
          title: "粉丝ID",
          align: "center",
          width: 80,
          dataIndex: "uid",
        },
        {
          title: "粉丝名称",
          align: "center",
          width: 120,
          dataIndex: "userInfo.username",
        },
        {
          title: "订单编号",
          align: "center",
          width: 180,
          dataIndex: "orderNo",
        },
        {
          title: "订单金额",
          align: "center",
          width: 80,
          dataIndex: "amount",
        },
        {
          title: "支付状态",
          align: "center",
          width: 100,
          dataIndex: "payStatus",
          scopedSlots: {customRender: "payStatus"},
        },
        {
          title: "分配状态",
          align: "center",
          width: 100,
          dataIndex: "assignStatus",
          scopedSlots: {customRender: "assignStatus"},
        },
        {
          title: "下单时间",
          align: "center",
          width: 160,
          dataIndex: "createTime",
        },
        {
          title: "备注",
          width: 160,
          dataIndex: "remark",
        },
        {
          title: "操作",
          width: 180,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    fullKbsImgPath,
    afterFetch(data) {
      data.records.forEach((v, index) => {
        if (v.kbsQueryJson) {
          v.kbsQueryJsonObj = JSON.parse(v.kbsQueryJson)
        }
      })
    },
    assignToken(record) {
      this.$post('cleanSale/assignToken', {id: record.id}).then(() => {
        this.$message.success('分配Token成功')
        this.search()
      })
    },

    deleteOne(record) {
      let that = this
      if (record.payStatus == 1) {
        that.$message.warn('已支付订单，不能删除')
        return
      }
      this.$confirm({
        title: '删除确认',
        content: '您确定要删除订单【' + record.id + '】吗？！',
        centered: true,
        onOk() {
          that.$post('cleanSale/deleteOrder', {id: record.id}).then(() => {
            that.$message.success('删除成功')
            that.search()
          })
        },
        onCancel() {
          that.selectedRowKeys = []
        }
      })
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
